<template>
    <div class="coming__soon">
        <h1>Está funcionalidad estará disponible pronto.</h1>
        <img src="../../../assets/img/soon.svg" alt="" srcset="">
    </div>
</template>

<script>
export default {
    name:"posts",
}
</script>